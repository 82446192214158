###*
# @ngdoc object
# @name lpaPoiOrderTracking.controller:PoiOrderTrackingCtrl
#
# @description
#
###
class PoiOrderTrackingCtrl
  ### @ngInject ###
  constructor: (
    $scope
    $state
    $stateParams
    Incidents
    $rootScope
    $log
    $window
    MundoSocket
    MundoMap
    $timeout
    $interval
    LpaMapUtils
    Proposals
    _
    ProposalList
    CommentsList
    ProposalManager
    RestUtils
    MapSync
    Tasks
    Statuses
    Routes
    ProposalRouteManager
    FocusManager
    $mdDialog
    mundoConfiguration
  ) ->

    # ----------------------------------------------------------
    # INITIALISTATION
    # ----------------------------------------------------------

    @poiOrders = {}
    @poiOrder = {}
    @features = {}
    @dispatchFeatures = mundoConfiguration.dispatchFeatures

    # Get the incident id from the URL
    @poiOrderId = $stateParams['poi_order_id']

    # How much time before the window refreshes automatically
    $scope.time = 60000

    # Default state of the info window
    @detailsGroupIsOpen = true

    # Override some params/functions of MundoMap
    @map = MundoMap.createInstance 'dispatch-map',
      view:
        maxZoom: 17
        zoom: 13
      follow:
        objects: []
        enabled: true
        speedZoom: false
        zoomLevel: 15
      search:
        enabled: false

    # Initalise all the layers and set the appropriate styles
    @incidentLayer = MundoMap.getLayerById(@map, 'incidents')
    @trailLayer = MundoMap.getLayerById(@map, 'trails')
    @routesLayer = MundoMap.getLayerById(@map, 'routes')
    @vehicleLayer = MundoMap.getLayerById(@map, 'markers')
    @poiOrderLayer = MundoMap.getLayerById @map, 'poiOrders'

    LpaMapUtils.setIncidentLayerStyle @incidentLayer
    LpaMapUtils.setTrailsLayerStyle @trailLayer
    LpaMapUtils.setProposalVehicleLayerStyle @vehicleLayer
    LpaMapUtils.setRoutesLayerStyle @routesLayer
    LpaMapUtils.setPOIOrderLayerStyle @poiOrderLayer

    # Add a popup overlay
    @popupOverlay = new ol.Overlay.Popup
      insertFirst: false
    @map.addOverlay @popupOverlay

    MapSync.poiOrders.enable @map, @features, @poiOrders, @poiOrderLayer,
      listen: ['task_assign', 'task_relieve', 'task_update']
      filter: @applyPOIOrderFilters
      onUpdate: (oldPOIOrder, updatedPOIOrder) =>
        @poiOrder = updatedPOIOrder

    MapSync.units.enable
      map: @map
      markers: @features
      statuses: {}
      markerLayer: @vehicleLayer
      filter: @applyUnitFilters
      visibleUnits: []
      trailLayer: @trailLayer
      automaticallyAddNewStatuses: false

    # Load the initial POIOrder

    @loadPOIOrder = () =>

      @poiOrderPromise = RestUtils.getFullList Tasks,
        'filter[]': [
            "id," + @poiOrderId
          ]

      @poiOrderPromise.then (result) =>
        @poiOrder = result[0]
        LpaMapUtils.addPOIOrderToMap @poiOrder, @features, @poiOrderLayer, @poiOrders
    
    # ----------------------------------------------------------
    # UI ACTIONS / TOGGLES
    # ----------------------------------------------------------

    # Toggle the little window on the bottom left with the POIOrder details
    @toggleDetails = (ev) =>
      ev.stopPropagation()
      @detailsGroupIsOpen = !@detailsGroupIsOpen

    # Show the comments/logs of this POIOrder in a popup
    @showComments = () ->
      CommentsList.showPOIOrderComments(@poiOrder.lpaPoiOrder.id)

    # Do an actual complete page reload
    @reload = () ->
      location.reload()

    # Open a new vehicle tracking window
    @openFastTrackVehicleWindow = (status) ->
      url = $state.href('fastTracking', {status_id: status['_id']}, {absolute: true})
      openedWindow = window.open url, 'uniqueName' + status['_id'], 'width=1100,height=800', false
      # This return is added because else we have a console error
      # Coffeescript changes the 'window.open' to 'return window.open()' what generates a console error
      return true

    # Open a new vehicle incident tracking window
    @openIncidentWindow = (incident) ->
      url = $state.href('incidentTracking', {incident_id: incident['id']}, {absolute: true})
      openedWindow = window.open url, 'uniqueName' + incident['id'], 'width=1100,height=800', false
      # This return is added because else we have a console error
      # Coffeescript changes the 'window.open' to 'return window.open()' what generates a console error
      return true

    # Open a new vehicle POIOrder tracking window
    @openPOIOrderWindow = (poiOrder) ->
      url = $state.href('poiOrderTracking', {poi_order_id: poiOrder['id']}, {absolute: true})
      openedWindow = window.open url, 'uniqueName' + poiOrder['id'], 'width=1100,height=800', false
      # This return is added because else we have a console error
      # Coffeescript changes the 'window.open' to 'return window.open()' what generates a console error
      return true

    @closePOIOrder = () ->
      FocusManager.closePOIOrder @poiOrder
      .then () ->
        window.close()

    @editPOIOrder = () ->
      FocusManager.editPOIOrder @poiOrder
      .then () ->
        $mdDialog.hide()
      
    # ----------------------------------------------------------
    # MAP FUNCTIONALITY
    # ----------------------------------------------------------

    # Handle click events on the map
    @map.on "click", (e) =>
      @map.forEachFeatureAtPixel e.pixel, (f, l) =>
        layerProps = l.getProperties()
        featureProps = f.getProperties()

        if layerProps._layerId is "markers"
          if featureProps.features.length == 1
            angular.forEach featureProps.features, (feature)->
              LpaMapUtils.openFastTrackVehicleWindow feature.getProperties()._status
          else
            extent = ol.extent.createEmpty()
            angular.forEach featureProps.features, (feature)->
              ol.extent.extend extent, feature.getGeometry().getExtent()
              # Define the nice animations for panning and zooming
            pan = ol.animation.pan
              duration: 500
              source: @map.getView().getCenter()

            zoom = ol.animation.zoom
              duration: 500
              resolution: @map.getView().getResolution()

            @map.beforeRender(pan)
            @map.beforeRender(zoom)

            @map
              .getView()
              .fit extent, @map.getSize(),
                padding: [100, 100, 100, 100]
                constrainResolution: true
        if layerProps._layerId is "incidents"
          @openIncidentWindow featureProps._incident
        if layerProps._layerId is "poiOrders"
          @openPOIOrderWindow featureProps._poiOrder

    # Function to pan the map to a list of specific features
    @panToFeatures = (features) =>
      if features?
        extent = ol.extent.createEmpty()

        angular.forEach features, (feature) ->
          ol.extent.extend extent, feature.getGeometry().getExtent()

        pan = ol.animation.pan
          duration: 400
          source: @map.getView().getCenter()

        zoom = ol.animation.zoom
          duration: 400
          resolution: @map.getView().getResolution()

        @map.beforeRender(pan)
        @map.beforeRender(zoom)
        @map
          .getView()
          .fit extent, @map.getSize(),
            padding: [100, 100, 100, 100]
            constrainResolution: true

    # ----------------------------------------------------------
    # HELPERS
    # ----------------------------------------------------------

    # Helper function to get the keys of an object
    @count = (object) ->
      return Object.keys(object).length

    @loadPOIOrder()

angular
  .module 'lpaPoiOrderTracking'
  .controller 'PoiOrderTrackingCtrl', PoiOrderTrackingCtrl
